<template>
  <div id="member-subscription-create">
    <v-card>
      <v-card-title class="text-h5">
        <h2> {{ PageTitle }}</h2>

        <v-spacer />
        <v-btn
          medium
          @click.prevent="pageData"
          color="#a4c639"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text"
          >&nbsp; Refresh &nbsp;
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
        </v-card-subtitle>

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once. </li>
            <li>After paying regular subscription, choose additional subcription. </li>
            <li>Late fee is optional but paying late fee is required to get voting rights. </li>
            <li>After completing the payment, kindly select your members for whom the payment was made. </li>
          </ol>
        </v-container>
        <h6>* indicates required field</h6>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-form ref="form2" v-model="valid1" lazy-validation>
          <v-container v-if="!StartupLoadingFlag">
              <v-row wrap>
                <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Lom Type</h6>
                    </label>
                  <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="LomTypeOptionsLoading"
                      :items="LomTypeOptions"
                      :rules="LomTypeRules"
                      v-model="LomType"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Year</h6>
                  </label>
                  <v-autocomplete
                    @change="getZoneCodeOptions"
                    :reduce="(option) => option.value"
                    :loading="JciYearCodeOptionsLoading"
                    :items="JciYearCodeOptions"
                    :rules="JciYearCodeRules"
                    v-model="JciYearCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Zone</h6>
                    </label>
                  <v-autocomplete
                      @change="getLomCodeOptions"
                      :reduce="(option) => option.value"
                      :loading="ZoneCodeOptionsLoading"
                      :items="ZoneCodeOptions"
                      :rules="ZoneCodeRules"
                      v-model="ZoneCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> LOM</h6>
                  </label>
                  <v-autocomplete
                      @change="getMemberDetails"
                      :reduce="(option) => option.value"
                      :loading="LomCodeOptionsLoading"
                      :items="LomCodeOptions"
                      :rules="LomCodeRules"
                      v-model="LomCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                </v-col>
                              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> State Of LO</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateCodeOptionsLoading"
                  :items="StateCodeOptions"
                  :rules="StateCodeRules"
                  v-model="StateCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> District Of LO</h6>

                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictCodeOptionsLoading"
                  :items="DistrictCodeOptions"
                  :rules="DistrictCodeRules"
                  v-model="DistrictCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> City Of LO</h6>

                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityCodeOptionsLoading"
                  :items="CityCodeOptions"
                  :rules="CityCodeRules"
                  v-model="CityCode"
                  dense
                  clearable
                  required
                  outlined
                  base-color="white"
                ></v-autocomplete>
              </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label v-if="!MemberFlag && LomType == 1">
                    <h6><span class="text-danger">*</span> New Member Name</h6>
                  </label>
                  <label v-else>
                    <h6><span class="text-danger">*</span> President Name</h6>
                  </label>
                  <v-row v-if="!MemberFlag && LomType == 1">
                    <v-col cols="12">
                      <v-text-field
                        v-model="MemberName"
                        :rules="MemberNameRules"
                        type="email"
                        hint="Enter your Member Name"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="!MemberFlag && LomType == 2">
                    <v-col cols="12">
                      <h4 class="text-danger"> NA </h4>
                    </v-col>
                  </v-row>
                  <v-row v-if="MemberFlag">
                      <v-col cols="3">
                          <v-img
                          :src="Member.MemberImagePath"
                          :alt="Member.MemberName"
                          lazy-src="/no-image-available.png"
                          max-width="50"
                          max-height="50"
                          >
                          <template v-slot:placeholder>
                              <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                              >
                              <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                              ></v-progress-circular>
                              </v-row>
                          </template>
                          </v-img>
                      </v-col>
                      <v-col cols="9">
                          <h4>{{ Member.MemberName }}</h4>
                          <h4>{{ Member.MembershipId }}</h4>
                      </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="!MemberFlag && LomType == 1">
                  <label>
                    <h6><span class="text-danger">*</span> Mobile Number</h6>
                  </label>
                  <v-text-field
                    v-model="MobileNo"
                    :rules="MobileNoRules"
                    :counter="10"
                    v-mask="'##########'"
                    type="number"
                    hint="Enter your email id"
                    dense
                    outlined
                  ></v-text-field>
                  <!-- <h4>{{ Member.MobileNo }}</h4> -->
                </v-col>
                <v-col cols="12" sm="6" md="3" v-else>
                  <label>
                    <h6><span class="text-danger">*</span> President Mobile Number</h6>
                  </label>
                  <v-text-field
                    v-model="Member.PrimaryMobile"
                    :rules="MobileNoRules"
                    :counter="10"
                    v-mask="'##########'"
                    type="number"
                    hint="Enter your email id"
                    dense
                    outlined
                  ></v-text-field>
                  <!-- <h4>{{ Member.MobileNo }}</h4> -->
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="!MemberFlag && LomType == 1">
                  <label>
                    <h6><span class="text-danger">*</span> Email Id</h6>
                  </label>
                  <v-text-field
                    v-model="EmailId"
                    :rules="EmailIdRules"
                    type="email"
                    hint="Enter your email id"
                    dense
                    outlined
                  ></v-text-field>
                  <!-- <h4>{{ Member.EmailId }}</h4> -->
                </v-col>
                <v-col cols="12" sm="6" md="3" v-else>
                  <label>
                    <h6><span class="text-danger">*</span> President Email Id</h6>
                  </label>
                  <v-text-field
                    v-model="Member.EmailId"
                    :rules="EmailIdRules"
                    type="email"
                    hint="Enter your email id"
                    dense
                    outlined
                  ></v-text-field>
                  <!-- <h4>{{ Member.EmailId }}</h4> -->
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Does the LOM has GST Number?</h6>
                  </label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="GstNumberFlagOptions"
                    :rules="GstNumberFlagRules"
                    v-model="GstNumberFlag"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="GstNumberFlag == 1 && !MemberFlag && LomType == 1">
                  <label>
                    <h6><span class="text-danger">*</span> GST Number</h6>
                  </label>
                  <v-text-field
                    v-model="GstNumber"
                    :rules="GstNumberRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="GstNumberFlag == 1 && MemberFlag">
                  <label>
                    <h6><span class="text-danger">*</span> GST Number</h6>
                  </label>
                  <v-text-field
                    v-model="Member.LomGstNumber"
                    :rules="GstNumberRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Paid Date</h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="PaidDate"
                        label="Paid Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="PaidDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Transaction Type</h6>
                  </label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="TransactionTypeIdOptions"
                    :rules="TransactionTypeIdRules"
                    v-model="TransactionTypeId"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="TransactionTypeId != ''">
                  <label>
                    <h6><span class="text-danger">*</span> Payment Id / Checque No. / DD No.</h6>
                  </label>
                  <v-text-field
                    v-model="PaymentId"
                    :rules="PaymentIdRules"
                    hint="Enter the transaction reference no"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="TransactionTypeId != 1 && TransactionTypeId != ''">
                  <label>
                    <h6><span class="text-danger">*</span> Transaction Fees</h6>
                  </label>
                  <v-text-field
                    v-model="TransactionFees"
                    :rules="TransactionFeesRules"
                    min="0"
                    type="number"
                    hint="Enter the transaction fee amount"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                      <v-btn
                          @click.prevent="searchForm"
                          :loading="SearchFlag"
                          target="_blank"
                          color="#8950FC"
                          class="
                          btn btn-primary
                          font-size-h6
                          px-15
                          py-4
                          my-3
                          mr-3
                          white--text
                          "
                      >
                          <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                          Search
                      </v-btn>
                  </v-col>
              </v-row>
          </v-container>

            <v-container class="py-0" v-if="SearchFlag">
              <v-skeleton-loader
                v-bind="attrs"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-container>

            <v-container class="py-0" v-if="SearchResultFlag">
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="#8950FC"
                  centered
                  dark
                  icons-and-text
                  show-arrows
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1" :disabled="TabDisable1">
                    Subscription Payment
                    <v-icon>mdi-numeric-1-box-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-2" :disabled="TabDisable2">
                    Late Fee Payment
                    <v-icon>mdi-numeric-2-box-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3" :disabled="TabDisable3" v-if="TabFlag3">
                    Senior Members Association
                    <v-icon>mdi-numeric-3-box-outline</v-icon>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <v-card flat>
                      <p></p>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            List of regular subscriptions
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <!-- <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Search records here"
                                  single-line
                                  hide-details
                                ></v-text-field> -->
                          <br />
                          <v-data-table
                            class="elevation-1"
                            disable-sort
                            disable-filtering
                            v-model="selected"
                            :loading="SearchFlag"
                            loading-text="Loading... Please wait"
                            :headers="tableColumns1"
                            :items="rows"
                            :items-per-page="50"
                            :search="search"
                            item-key="YearwiseContributionId"
                            :single-select="false"
                            :show-select="false"
                            :hide-default-footer="true"
                            :footer-props="{
                              'items-per-page-options': [10, 20, 30, 40, 50],
                            }"
                          >
                            <template v-slot:item.FineCheckBox="{ item }">
                              <v-checkbox
                                v-if="item.FineFlag"
                                v-model="item.FinePaidFlag"
                                :label="item.FineAmountTxt"
                                color="info"
                                :value="item.FinePaidFlag"
                                hide-details
                                v-on="calculateTotal()"
                              ></v-checkbox>
                            </template>
                            <template v-slot:item.Qty="{ item }">
                              <v-text-field
                                v-model="item.Qty"
                                v-on="calculateTotal()"
                                type="number"
                                min="0"
                                :rules="QtyRules"
                              ></v-text-field>
                            </template>
                            <template v-slot:item.SubTotal="{ item }">
                              {{ item.SubTotal }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            Total Amount : Rs. {{ TotalAmount1 }}
                          </h3>
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="submitRegularSubscription"
                            :loading="SubmitFlag"
                            color="#8950FC"
                            elevation="30"
                            shaped
                            tile
                            large
                            class="
                              btn btn-primary
                              font-size-h6
                              px-15
                              py-4
                              my-3
                              mr-3
                              white--text
                            "
                          >
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-2">
                    <v-card flat>
                      <p></p>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            List of late fee subscriptions
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <!-- <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Search records here"
                                  single-line
                                  hide-details
                                ></v-text-field> -->
                          <br />
                          <v-data-table
                            class="elevation-1"
                            disable-sort
                            disable-filtering
                            v-model="selected"
                            :loading="SearchFlag"
                            loading-text="Loading... Please wait"
                            :headers="tableColumns2"
                            :items="PenaltySubscription"
                            :items-per-page="50"
                            :search="search"
                            item-key="LomPaymentId"
                            :single-select="false"
                            :show-select="false"
                            :hide-default-footer="true"
                            :footer-props="{
                              'items-per-page-options': [10, 20, 30, 40, 50],
                            }"
                          >
                            <template v-slot:item.FineCheckBox="{ item }">
                              <v-checkbox
                                v-if="item.FineFlag"
                                v-model="item.FinePaidFlag"
                                :label="item.FineAmountTxt"
                                color="info"
                                :value="item.FinePaidFlag"
                                hide-details
                                v-on="calculatePenaltyTotal()"
                              ></v-checkbox>
                            </template>
                            <template v-slot:item.Qty="{ item }">
                              <v-text-field
                                v-model="item.Qty"
                                v-on="calculatePenaltyTotal()"
                                type="number"
                                min="0"
                                :rules="QtyRules"
                              ></v-text-field>
                            </template>
                            <template v-slot:item.SubTotal="{ item }">
                              {{ item.SubTotal }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            Total Amount : Rs. {{ TotalAmount2 }}
                          </h3>
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="submitPenaltySubscription"
                            :loading="SubmitFlag"
                            color="#8950FC"
                            elevation="30"
                            shaped
                            tile
                            large
                            class="
                              btn btn-primary
                              font-size-h6
                              px-15
                              py-4
                              my-3
                              mr-3
                              white--text
                            "
                          >
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-3" v-if="TabFlag3">
                    <v-card flat height="300px">
                      <br/></br>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <v-btn
                            href="https://seniors.jciindia.in/join-us"
                            target="_blank"
                            elevation="30"
                            shaped
                            tile
                            small
                            color="blue"
                            class="animate-button1"
                          >
                            Pay Subscription Fees for Senior Member Associan
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
              <!-- <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3>{{ rows.length }} loms found.</h3>
              </v-col>
            </v-row> -->
            </v-container>
          </v-form>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      SearchResultFlag: false,
      NotesFlag: false,
      rows: [],
      tableColumns1: [],
      tableColumns2: [
        { text: "Date", value: "PaidDateTxt", width: "13%" },
        { text: "Description", value: "Description", width: "35%" },
        { text: "Late Fee", value: "FineAmountTxt", width: "13%" },
        { text: "To be paid", value: "FineNotPaid", width: "13%" },
        { text: "No of members", value: "Qty", width: "13%" },
        { text: "Sub Total", value: "SubTotal", width: "13%" },
      ],
      selected: [],
      search: "",

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      TabFlag3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      OrderType: 1,

      LomTypeRules: [(v) => !!v || "LOM Type is required"],
      LomType: "",
      LomTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "New Lom" },
        { value: 2, text: "Existing Lom" },
      ],
      LomTypeOptionsLoading: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      MemberName: "",
      MemberNameRules: [(v) => !!v || "Member Name is required"],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumberFlag: 2,
      GstNumberFlagRules: [
        (v) => !!v || "Does your LOM has GST number field required",
      ],
      GstNumberFlagOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      GstNumber: "",
      GstNumberRules: [(v) => !!v || "GST number is required"],

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      TransactionTypeId: "",
      TransactionTypeIdRules: [
        (v) => !!v || "Transaction type should not be empty",
      ],
      TransactionTypeIdOptions: [],
      TransactionTypeIdOptionsLoading: false,

      PaymentId: "",
      PaymentIdRules: [(v) => !!v || "Payment Id is required"],

      TransactionFees: 0,
      TransactionFeesRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
      this.getZoneCodeOptions();
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    PaidDate: function () {
      console.log("watch PaidDate");
      this.resetSubscription();
    },
    TransactionTypeIdOptions: function () {
      console.log("watch TransactionTypeIdOptions");
      this.TransactionTypeIdOptionsLoading = false;
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.getDistrictCodeOptions();
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.getCityCodeOptions();
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
    CityCode: function () {
      console.log("watch CityCode");
      console.log("CityCode" + this.CityCode);
      console.log("CityId" + this.Member.CityId);
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "membership_payment",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.StateCode = "";
      this.DistrictCode = "";
      this.CityCode = "";
      this.getJciYearCodeOptions();
      this.getStateCodeOptions();
      this.getTransactionTypeIdOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    resetMember() {
      console.log("resetMember called");
      this.LomCode = "";
      this.Member = {
        MemberId: "",
        PrimaryMobile: "",
        EmailId: "",
      };
      this.MemberFlag = false;
    },
    resetSubscription() {
      this.SearchResultFlag = false;
      this.rows = [];
      this.PenaltySubscription = [];
    },
    getTransactionTypeIdOptions() {
      console.log("getTransactionTypeIdOptions called");
      this.TransactionTypeIdOptionsLoading = true;
      var selectbox1_source = "TransactionTypeId";
      var selectbox1_destination = "TransactionTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.resetMember();
        this.resetSubscription();
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.resetMember();
        this.resetSubscription();
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      this.DistrictCodeOptions = [];
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode > 0) {
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      this.CityCodeOptions = [];
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode > 0) {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    submitRegularSubscription() {
      console.log("submitRegularSubscription called");
      this.OrderType = 1;
      this.TotalAmount = this.TotalAmount1;
      this.Contribution = this.rows;
      this.confirmAlert();
    },
    submitPenaltySubscription() {
      console.log("submitPenaltySubscription called");
      this.OrderType = 2;
      this.TotalAmount = this.TotalAmount2;
      this.Contribution = this.PenaltySubscription;
      this.confirmAlert();
    },
    calculateTotal() {
      console.log("calculateTotal called");
      var n1 = this.rows.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", rows=" + JSON.stringify(this.rows));
      for (var i = 0; i < n1; i++) {
        var qty = this.rows[i]["Qty"];
        var PenaltyAmount = this.rows[i]["FineAmount"];
        var WithoutFineAmount = this.rows[i]["WithoutFineAmount"];
        var FinePaidFlag = this.rows[i]["FinePaidFlag"];
        var amount = this.rows[i]["Amount"];
        console.log(
          "i=" +
            i +
            ", qty=" +
            qty +
            ", PenaltyAmount=" +
            PenaltyAmount +
            ", WithoutFineAmount=" +
            WithoutFineAmount +
            ", FinePaidFlag=" +
            FinePaidFlag +
            ", amount=" +
            amount
        );
        amount = FinePaidFlag ? amount : WithoutFineAmount;
        PenaltyAmount = FinePaidFlag ? PenaltyAmount : 0;
        console.log("amount=" + amount + ", PenaltyAmount=" + PenaltyAmount);
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.rows[i]["PenaltyAmount"] = PenaltyAmount;
        this.rows[i]["PenaltyAmountTxt"] = this.getDecimalNumber(
          PenaltyAmount,
          2
        );
        this.rows[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.rows.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.TotalAmount1 = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    calculatePenaltyTotal() {
      console.log("calculateTotal called");
      var rows = this.PenaltySubscription;
      var n1 = rows.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", rows=" + JSON.stringify(this.rows));
      for (var i = 0; i < n1; i++) {
        var qty = rows[i]["Qty"];
        var amount = rows[i]["FineAmount"];
        console.log("i=" + i + ", qty=" + qty + ", amount=" + amount);
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.PenaltySubscription[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.PenaltySubscription.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.TotalAmount2 = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    checkout() {
      console.log("checkout");
      this.pageRedirect({}, "/members/subscription/payment/checkout");
    },

    getMemberDetails() {
      console.log("getMemberDetails called");
      this.resetSubscription();
      var temp_code = this.LomCode;
      console.log("temp_code=" + temp_code);
      var idx = this.LomCodeOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.LomCodeOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.Member = member;
        this.MemberFlag = member.MemberName != "" ? true : false;
        this.StateCode = member.LomStateId;
        this.DistrictCode = member.LomDistrictId;
        this.CityCode = member.LomCityId;
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      var validate1 = this.$refs.form2.validate();
      var MemberId = this.Member.MemberId;
      var MemberFlag = this.MemberFlag;
      var PaidDate = this.PaidDate;
      var LomType = this.LomType;
      var MemberName = this.MemberName;
      LomType = MemberFlag ? 2 : LomType;
      this.LomType = LomType;

      console.log(
        "validate1=" +
          validate1 +
          ", MemberId=" +
          MemberId +
          ", LomType=" +
          LomType +
          ", MemberName=" +
          MemberName +
          ", PaidDate=" +
          PaidDate
      );
      if (
        validate1 &&
        PaidDate != "" &&
        ((LomType == 2 && MemberId != "") || (LomType == 1 && MemberName != ""))
      ) {
        this.getRegularSubscriptionList();
        this.getPenaltySubscriptionList();
      } else {
        var message = "";
        if (!validate1) {
          message = "Kindly fill the required fields. ";
        }
        if (LomType == 2 && MemberId == "") {
          message += "Kindly assign President for the selected LOM. ";
        }
        if (LomType == 1 && MemberName == "") {
          message += "Member Name should not be empty. ";
        }
        if (PaidDate == "") {
          message += "Paid date should not be empty. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
    getRegularSubscriptionList() {
      console.log("getRegularSubscriptionList is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-contribution/lists";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          LomCode: this.LomCode,
          PaidDate: this.PaidDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SearchResultFlag = true;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;
              thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "";
        if (JciYearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getPenaltySubscriptionList() {
      console.log("getPenaltySubscriptionList is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.PenaltySubscription = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/lists";
        var upload = {
          UserInterface: 2,
          LomCode: this.LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SearchResultFlag = true;
              // thisIns.tableColumns1 = records.TableHeader;
              thisIns.PenaltySubscription = records;
              thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Lom should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      var MemberId = this.Member.MemberId;
      var PaidDate = this.PaidDate;
      var LomType = this.LomType;
      var MemberName = this.MemberName;
      var TotalAmount = this.TotalAmount;
      var MemberFlag = this.MemberFlag;
      LomType = MemberFlag ? 2 : LomType;
      this.LomType = LomType;

      console.log(
        "validate1=" +
          validate1 +
          ", MemberId=" +
          MemberId +
          ", LomType=" +
          LomType +
          ", MemberName=" +
          MemberName +
          ", PaidDate=" +
          PaidDate +
          ", TotalAmount=" +
          TotalAmount
      );

      // TotalAmount=0;

      if (
        validate1 &&
        PaidDate != "" &&
        TotalAmount > 0 &&
        ((LomType == 2 && MemberId != "") || (LomType == 1 && MemberName != ""))
      ) {
        var tempContribution = this.Contribution;
        let Contribution = tempContribution.filter(function (e) {
          return e.Qty > 0;
        });
        console.log(Contribution);

        var htmlTxt = "";
        // var ContributionTxt = JSON.stringify(Contribution);

        Contribution.forEach((item) => {
          console.log("item=" + JSON.stringify(item));
          htmlTxt +=
            item["Description"] + " - <b>" + item["Qty"] + " Nos </b><br/>";
        });

        htmlTxt += "<br/>Total amount <b> Rs." + TotalAmount + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        if (LomType == 2 && MemberId == "") {
          message += "Kindly assign President for the selected LOM. ";
        }
        if (LomType == 1 && MemberName == "") {
          message += "Member Name should not be empty. ";
        }
        if (PaidDate == "") {
          message += "Paid date should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var TotalAmount = this.TotalAmount;
      console.log("validate1=" + validate1 + ", TotalAmount=" + TotalAmount);

      // TotalAmount=0;

      if (validate1 && TotalAmount > 0) {
        this.SubmitFlag = true;
        this.TabDisable1 = true;
        this.TabDisable2 = true;

        // Get Year name from JciYearCodeOptions

        var JciYearCode = this.JciYearCode;
        var JciYearCodeIndex = this.JciYearCodeOptions.map(
          (e) => e.value
        ).indexOf(JciYearCode);
        console.log(
          "JciYearCode=" +
            JciYearCode +
            ", JciYearCodeIndex=" +
            JciYearCodeIndex
        );
        var JciYearName = this.JciYearCodeOptions[JciYearCodeIndex].text;
        console.log("JciYearName=" + JciYearName);

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/offline";

        var upload = {
          LomType: this.LomType,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
          LomCode: this.LomCode,
          OrderType: this.OrderType,
          YearName: JciYearName,
          LoState: this.StateCode,
          LoDistrict: this.DistrictCode,
          LoCity: this.CityCode,
          GstNumberFlag: this.GstNumberFlag,
          PaidDate: this.PaidDate,
          TransactionTypeId: this.TransactionTypeId,
          PaymentId: this.PaymentId,
          TransactionFees: this.TransactionFees,
          TotalAmount: this.TotalAmount,
          Member: this.Member,
          Contribution: this.Contribution,
        };

        var LomType = this.LomType;

        if (LomType == 1) {
          upload.MemberId = 0;
          upload.MemberName = this.MemberName;
          upload.PrimaryMobile = this.MobileNo;
          upload.EmailId = this.EmailId;
          upload.GstNumber = this.GstNumber;
        } else {
          upload.MemberId = this.Member.MemberId;
          upload.MemberName = this.Member.MemberName;
          upload.PrimaryMobile = this.Member.PrimaryMobile;
          upload.EmailId = this.Member.EmailId;
          upload.GstNumber = this.Member.LomGstNumber;
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        // this.$session.set("checkout", upload);
        // this.checkout();

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.TabDisable1 = false;
            thisIns.TabDisable2 = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.resetForm();
              thisIns.resetMember();
              thisIns.resetSubscription();
              thisIns.sweetAlert("success", output, true);
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.TabDisable1 = false;
            thisIns.TabDisable2 = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
  },
  beforeMount() {
    if (this.$session.has("checkout")) {
      this.$session.remove("checkout");
    }
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#member-subscription-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
}
</style>